import AbpBase from '@/lib/abpbase'
import Util from '@/lib/util'

declare global {
    interface ListColumn {
        value: string;
        text: string;
        key: string;
        title: string;
        render: Function;
    }
}

export default class listBase extends AbpBase{
    entity: string = '';
    modalShow: boolean = false;
    createModalShow: boolean = false;
    editModalShow: boolean = false;
    addrPrefix: string = 'address_';
    //loadedTypes: boolean = false;
    rules: any = {
        required: this.L('{field} es obligatorio'),
        minVal: this.L('{field} el valor minimo es {value}'),
        maxVal: this.L('{field} el valor maximo es {value}'),
        minLen: this.L('{field} debe tener al menos {value} digitos'),
        maxLen: this.L('{field} debe tener maximo {value} digitos'),
    };
    items: Array<any> = [];

    get list() {
        return this.$store.state[this.entity].list;
    }
    get loading() {
        return this.$store.state[this.entity].loading;
    }
    get pageSize() {
        return this.$store.state[this.entity].pageSize;
    }
    get totalCount() {
        return this.$store.state[this.entity].totalCount;
    }
    get currentPage() {
        return this.$store.state[this.entity].currentPage;
    }
    edit() {}
    getpage() { }

    async loadOptions(apiPath: string, params:any = null) {
        await this.$store.dispatch({
            type: apiPath,
            data: params
        });
    }

    gCol(key, title = '', type = 'text', width: any = '', sortable: boolean = true) {
        let result: any = {};
        
        result.key = key;
        result.type = type;
        
        if (title != undefined) 
            result.title = this.L(title);

        if (width != '' && width != undefined)
            result.width = width;

        //fields for v-data-table
        result.text = result.title;
        result.value = result.key;
        //result.align = 'left';
        result.sortable = sortable;

        return this.gColO(result);
    }

    gColO(data: any) {

        if (data.value == 'active') {
            data.type = 'boolean';
            if (data.text == undefined)
                data.text = this.L('Habilitado');
            //result.render = (h: any, params: any) => {
            //    return h('span', params.row.active ? this.L('Yes') : this.L('No'))
            //}
        } else if (data.type == 'date') {
            //result.render = (h: any, params: any) => {
            //    return h('span', new Date(params.row[key]).toLocaleDateString())
            //}
        } else if (data.type == 'number' || data.type == 'price') {
            if (data.decimals == undefined) data.decimals = 2;
        } else if (data.value == 'actions') {
            data = this.defaultActions();
        } else {
            
        }

        if (data.class == undefined) data.class = '';
        if (data.size == undefined) data.size = 6;

        data.class = (data.align == undefined ? 'text-start ' : 'text-' + data.align + ' ') + data.class;
        
        if (data.text != undefined)
            data.text = this.L(data.text);

        if (data.sortable == undefined)
            data.sortable = true;

        if (data.type == undefined)
            data.type = 'text';

        return data;
    }

    gItem(data : any) { 
        let result: any = {};
        if (data.size == undefined) data.size = 12;
        if (data.cols == undefined) data.cols = 12;
        result = Util.extend(true, {}, data);
        if (result.controltype == undefined) result.controltype = result.type;
        if (result.tab == undefined) result.tab = 'main';
        if (result.required == undefined) result.required = false;    
        if (result.visible == undefined) result.visible = true;    
        if (result.text != undefined) result.text = this.L(result.text);
        if (result.mask == undefined) result.mask = null;
        if (result.hint == undefined) result.hint = null;
        if (result.persistentHint == undefined) result.persistentHint = false;
        if (result.onchange == undefined) result.onchange = () => { };
        if (result.oninput == undefined) result.oninput = () => { };
        if (result.onfocus == undefined) result.onfocus = () => { };
        if (result.preendEvent == undefined) result.preendEvent = () => { };
        if (result.appendEvent == undefined) result.appendEvent = () => { };
        if (result.onclickClose == undefined) result.onclickClose = () => { };
        if (result.onenter == undefined) result.onenter = () => { };        
        if (result.outlined == undefined) result.outlined = false;  
        if (result.filled == undefined) result.filled = false;  
        if (result.shaped == undefined) result.shaped = false;  
        if (result.hideDetails == undefined) result.hideDetails = false;  
        if (result.dense == undefined) result.dense = true;  
        if (result.inline == undefined) result.inline = true;          
        
        if (result.exclShortkey) result.class = 'excl-shortkey';

        if (result.type == 'active') {
            result.controltype = 'switch';
            result.prop = 'active';
            result.label = this.L('Habilitado');
            result.defaultValue = true;
        } 

        if (result.label != undefined) result.label = this.L(result.label);  
         
        //required
        if (result.required) {
            if (result.rules == undefined) result.rules = [];
            result.rules.push(v => !!v || this.rules.required.replace('{field}', result.label));
        }

        if (result.minVal != undefined) {
            if (result.rules == undefined) result.rules = [];
            result.rules.push(v => parseInt(v) >= result.minVal || this.rules.minVal.replace('{field}', result.label).replace('{value}', result.minVal));
        }

        if (result.maxVal != undefined) {
            if (result.rules == undefined) result.rules = [];
            result.rules.push(v => parseInt(v) <= result.maxVal || this.rules.maxVal.replace('{field}', result.label).replace('{value}', result.maxVal));
        }

        if (result.minLen != undefined) {
            if (result.rules == undefined) result.rules = [];
            result.rules.push(v => !!v && v.length >= result.minLen || this.rules.minLen.replace('{field}', result.label).replace('{value}', result.minLen));
        }

        if (result.maxLen != undefined) {
            if (result.rules == undefined) result.rules = [];
            result.rules.push(v => !!v && v.length <= result.maxLen || this.rules.maxLen.replace('{field}', result.label).replace('{value}', result.maxLen));
        }

        //configuration for price
        if (result.type == 'price') {
            result.type = 'number';
            result.controltype = 'text';
            result.prefix = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            //result.mask = '#####.#####'
        }

        if (result.type == 'time') {
            result.type = 'time';
            result.controltype = 'text';
        }

        //configuration for porcentaje
        if (result.type == 'percent') {
            result.type = 'number';
            result.controltype = 'text';
            result.suffix = '%';
        }
               
        //configuration for number
        if (result.type == 'number') {
            result.controltype = 'text';            
        }

        //configuration for password
        if (result.type == 'password') {
            result.type = 'password';
            result.controltype = 'text'; 
            if (result.required) {
                if (result.rules == undefined) result.rules = [];
                result.rules.push(v => (v && v.length >= 8) || 'Minimo 8 caracteres');
            }
            result.icon = 'mdi-eye-off';
            result.appendEvent = (item) => {
                item.icon = (item.icon == 'mdi-eye-off' ? 'mdi-eye' : 'mdi-eye-off');
                item.type = (item.type == 'password' ? 'text' : 'password');
            };
        }

        if (result.prefixSelect == true) {
            if (result.prefixClass == undefined) result.prefixClass = 'prefix-select';
            else result.prefixClass = 'prefix-select ' + result.prefixClass;
        }

        //configuration for email
        if (result.type == 'email') {
            result.controltype = 'text';
            result.icon = 'email';
            if (result.required) {
                if (result.rules == undefined) result.rules = [];
                result.rules.push(v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Debe ser un Email valido.'
                });
            }
        }

        //configuration for website
        if (result.type == 'website') {
            result.controltype = 'text';
            result.prefix = 'http://';
        } 

        //configuration for website
        if (result.type == 'phone') {
            result.controltype = 'text';
            if (result.preicon == undefined) result.preicon = 'phone';
            if (result.mask == undefined) result.mask = 'phone';
        }

        //configuration for date
        if (result.type == 'date') {
            if (result.flexClass == undefined) result.flexClass = '';
            if (result.range == undefined) result.range = false;    
            result.flexClass += ' flex-datepicker';
            if (result.readonly == undefined) result.readonly = false;
        }   
	
	//configuration for textarea
        if (result.type == 'textarea') { 
            if (result.rows == undefined) result.rows = 2;
        } 

        //configuration for divider
        if (result.type == 'divider') {
            if (result.info == undefined) result.info = '';
            if (result.subheader == undefined) result.subheader = '';
        }  

        if (result.subheader != null)
            result.subheader = this.L(result.subheader);

        //configuration for buttongroup
        if (result.type == 'buttongroup') {
            if (result.flexClass == undefined) result.flexClass = 'text-center';
        } 
        
        if (result.flexClass == undefined) result.flexClass = '';

        //load options
        if (result.type == 'object' || result.type == 'radio' || result.type == 'select' ||
            result.type == 'checkboxlist' || result.type == 'sortablelist' || result.type == 'treeview' ||
            result.type == 'buttongroup' || result.type == 'accordionlist' || result.type == 'combobox' || result.prefixSelect == true)
        {            
            result.controltype = (result.type == 'object' ? 'select' : (result.prefixSelect == true ? result.controltype : result.type));
            if (result.itemValue == undefined) result.itemValue = 'id';
            if (result.itemText == undefined) result.itemText = 'name';
            if (result.sourceList == undefined) result.sourceList = 'activeList';
            if (result.loadOptions == undefined) result.loadOptions = true;
                        
            if (result.type == 'radio') {
                if (result.showAll == undefined) result.showAll = false;
            }

            if (result.controltype == 'select' || result.controltype == 'combobox') {
                if (result.returnObject == undefined) result.returnObject = false;
                if (result.filterVal == undefined) result.filterVal = null;
                if (result.filterParam == undefined) result.filterParam = 'id';
                if (result.defaultValue == '0') result.autoSelectFirst = true;
                if (result.clearable == undefined) result.clearable = (result.prop.indexOf('_id') > 0 || result.readonly ? false : true);   
                if (result.class == undefined) result.class = 'excl-shortkey';                
            }

            if (result.controltype == 'treeview') {
                if (result.selectable == undefined) result.selectable = false;
            }

            if (result.options == undefined)
            {                
                if (result.params == undefined) result.params = {};

                if (result.code == undefined) result.code = result.prop;
                if (result.entity == undefined) result.entity = result.prop.replace('_id', '').replace('_', '').toLowerCase();

                //is a general type
                if (result.generalType != undefined) {
                    result.options = Util.abp.list.getList(result.generalType);
                    result.loadOptions = false;
                    
                    //result.entity = 'generaltype';
                    //result.source = result.entity + '/getValuesByCode';
                    //if (result.code == undefined) result.code = result.entity + result.generalType;
                    //result.params = { code: result.generalType };
                }
                
                if (result.autocomplete) {
                    if (result.options == undefined) result.options = [];
                    if (result.source == undefined) result.source = result.entity + '/querySearch';
                    if (result.searchLen == undefined) result.searchLen = 2;
                    if (result.cacheItems == undefined) result.cacheItems = true;
                    result.preicon = 'search';
                    result.loadOptions = false;
                    result.params.query = '';
                }
                //else {
                //    if ((result.entity == 'generaltype' && !this.loadedTypes) || result.entity != 'generaltype') {
                //        if (result.entity == 'generaltype') this.loadedTypes = true; 
                //        this.loadOptions(result.source, params);
                //    }                        
                //}
                
                if (result.source == undefined) result.source = result.entity + '/getAllActive';

            } else {
                result.code = result.prop;
                if (Array.isArray(result.options))
                    result.options = result.options;
                else
                    result.options = this.gCommonList(result.options);

                result.loadOptions = false;
            }            
        }        
        
        return result;
    }

    setItem(items, prop, param: string, value: any) {
        items.forEach(item => {
            if (item.prop == prop) {
                item[param] = value;
            }
        });
    }

    changeCountry(id) {
        if (id == undefined) return;
        this.setItem(this.items, this.addrPrefix + 'city_id', 'filterVal', [id]);
    }

    onloadedDepartment() {}

    loadAddressData(model) {
        if (model != null && model.address != null) {
            if (model.address.country_id != null) this.changeCountry(model.address.country_id);
        }
    }

    gAddressItems(prefix: string = 'address', tab: string = '') {
        let result: any = [];

        if (prefix == "address") prefix += '_';
        this.addrPrefix = prefix;

        result.push(this.gItem({ type: 'object', prop: prefix + 'country_id', label: 'Pais', entity: 'country', returnObject: false, tab: tab, size: 3, onchange: this.changeCountry }));
        result.push(this.gItem({ type: 'combobox', prop: prefix + 'city_id', label: '', entity: 'city', returnObject: true, tab: tab, size: 9 }));
        result.push(this.gItem({ type: 'text', prop: prefix + 'address1', label: 'Direccion 1', tab: tab, preicon: 'home', size: 6 }));
        result.push(this.gItem({ type: 'text', prop: prefix + 'address2', label: 'Direccion 2', tab: tab, preicon: 'home', size: 6 }));
        result.push(this.gItem({ type: 'text', prop: prefix + 'other', label: 'Otros', tab: tab, size: 3 }));
        result.push(this.gItem({ type: 'text', prop: prefix + 'postcode', label: 'Codigo Postal', tab: tab, size: 3 }));
        return result;
    }

    gAddressColumns() {
        let result: any = [];
         
        result.push(this.gCol('country.name', 'Pais', 'text', 150));
        result.push(this.gCol('full_address', 'Direccion', 'text'));
        result.push(this.gCol('phone', 'Telefono', 'text', 150));
        result.push(this.gCol('phone_mobile', 'Celular', 'text', 150));
        result.push(this.defaultActions());

        return result;
    }

    gColumns(items: Array<any>,
        ignore: string = '',
        allowActions: boolean = true)
    {
        let colsResult: Array<any> = [];
        
        items.forEach(item => {
            if (ignore.indexOf(item.prop) < 0 && item.tab == 'main' && item.prop != undefined) {
                let key = item.prop;
                if (item.type == 'object' || item.type == 'radio' || item.type == 'select') {
                    key = item.prop + '.' + item.itemText;
                }
                colsResult.push(this.gCol(key, item.label, item.type, item.width));
            }
        });
        if (allowActions)
            colsResult.push(this.defaultActions());

        return colsResult;
    }

    gCommonList(type: string) {
        let result: any = [];

        if (type == "week_days") {
            result = [{ id: 1, name: this.L('Lunes') },
            { id: 2, name: this.L('Martes') },
            { id: 3, name: this.L('Miercoles') },
            { id: 4, name: this.L('Jueves') },
            { id: 5, name: this.L('Viernes') },
            { id: 6, name: this.L('Sabado') },
            { id: 7, name: this.L('Domingo') }];
        }
        else if (type == "discount_types") {
            result = [{ id: 'percent', name: '%' },
                { id: 'amount', name: 'S/' }];
        }
        else if (type == "items_per_page") {
            result = [{ id: 5, name: '5' },
                { id: 10, name: '10' },
                { id: 20, name: '20' },
                { id: 50, name: '50' }];
        }
        else if (type == "menu_orientations") {
            result = [{ id: 0, name: 'Izquierda' },
            { id: 1, name: 'Arriba' }];
        } 
        else if (type == "printer_numbers") {
            result = [{ id: 40, name: '40' },
                      { id: 48, name: '48' },
                      { id: 56, name: '56' },
                      { id: 64, name: '64' }];
        }
        else if (type == "attr_group") {
            result = [{ id: 'S', name: 'Seleccion Simple' },
                { id: 'M', name: 'Seleccion Multiple' },
                { id: 'C', name: 'Color' }];
        }
        else if (type == "years") {
            let year = parseInt(Util.abp.clock.today().substr(0, 4));
            result = [];
            for (var i = year; i >= 2019; i--) 
                result.push({ id: i, name: String(i) });
        }
        else if (type == "months") {
            result = [];
            let curr = parseInt((this as any).$moment().format('MM')) - 1;
            (this as any).$moment.months().forEach((item, index) => {
                if (index <= curr) {
                    result.push({ id: parseInt(index + 1), name: item });
                }
            });
        }
        else if (type == "months_12") {
            result = [];
            (this as any).$moment.months().forEach((item, index) => {
                result.push({ id: parseInt(index + 1), name: item });
            });
        }
        else if (type == "levels") {
            let maxLevel = Util.abp.setting.get('MATRIX_MAX_LEVEL');
            result = [];
            for (var i = 1; i <= maxLevel; i++)
                result.push({ id: i, name: String(i) });
        }
        else if (type == "closure_types") {
            result = [
                { id: 'D', name: 'Diario' },
                { id: 'M', name: 'Mensual' }];
        }
        else if (type == "report_modes") {
            result = [
                { id: 'G', name: 'General' },
                { id: 'D', name: 'Detallado' }];
        }
        else if (type == "duration_types") {
            result = [
                { id: 'M', name: 'Minutos' },
                { id: 'H', name: 'Horas' },
                { id: 'D', name: 'Dias' }];
        }
        else if (type == "move_types") {
            result = [
                { id: '1', name: 'Ingreso' },
                { id: '-1', name: 'Retiro' }];
        }
        else if (type == "print_formats") {
            result = [
                { id: 'A4', name: 'A4' },
                { id: 'TK', name: 'Ticket' }];
        }
        else if (type == "methods_types") {
            result = [
                { id: 'E', name: 'Efectivo' },
                { id: 'T', name: 'Tarjeta' },
                { id: 'D', name: 'Deposito' },
                { id: 'G', name: 'Gratuito' }];
        }
        else if (type == "range_modes") {
            result = [
                { id: 'M1', name: 'Calculo de Rangos por Volumen de Red' },
                { id: 'M2', name: 'Calculo de Rangos por Volumen de Red y Rango Predecesor' }];
        }
        else if (type == "tree_modes") {
            result = [
                { id: 'H', name: 'Estructura de Arbol Horizontal' },
                { id: 'V', name: 'Estructura de Carpetas Vertical' }];
        }
        
        
        
        return result;
    }
        
    get getDefActions() {
        return {
            view: { name: 'view', color: 'secondary', title: 'Ver', class: 'mr-1', icon: 'visibility', event: 'viewItem' },
            edit: { name: 'edit', color: 'primary', title: 'Editar', class: 'mr-1', icon: 'edit', event: 'editItem' },
            print: { name: 'print', color: 'secondary', title: 'Imprimir', class: 'mr-1', icon: 'print', event: 'printItem' },
            delete: { name: 'delete', color: 'error', title: 'Eliminar', icon: 'delete', event: 'deleteItem' },
        }
    }

    defaultActions(sActions = null, width: number = 100, groupActions = null) {
        let actions = [];        
        let groActions = null;        
        if (sActions == null) {
            if (this.canEdit()) actions.push(this.getDefActions.edit);
            if (this.canDelete()) actions.push(this.getDefActions.delete);
        }  
         
        else if (typeof sActions === 'string') {
            let acts = sActions.split('|');
            for (var i = 0; i < acts.length; i++) {
                if ((acts[i] == 'edit' && this.canEdit()) ||
                    (acts[i] == 'delete' && this.canDelete()) ||
                    (acts[i] != 'edit' && acts[i] != 'delete'))
                    actions.push(this.getDefActions[acts[i]]);
            }
        } else {
            sActions.forEach(item => {
                if (item.name == 'edit') {
                    if (this.canEdit())
                        actions.push(item);
                } else if (item.name == 'delete') {
                    if (this.canDelete())
                        actions.push(item);
                } else 
                    actions.push(item);
            });
        }

        if (groupActions != null && groupActions != undefined) {
            groActions = [];
            groupActions.forEach(item => {
                if (item.name == 'edit') {
                    if (this.canEdit())
                        groActions.push(item);
                } else if (item.name == 'delete') {
                    if (this.canDelete())
                        groActions.push(item);
                } else
                    groActions.push(item);
            });
        }
        
        return {
            title: this.L('Actions'),
            value: 'action',
            class: 'text-center',
            sortable: false,
            width: width,
            actions: actions,
            groupActions: groActions
        }
    }

    getInitials(string, first = false) {
        return Util.getInitials(string, first);
    }
       
    formatNumber(amount, decimals = 0) {
        return Util.formatNumber(amount, decimals);
    }

    onFocus(e) {
        e.target.select();
    }
}